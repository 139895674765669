<template>
  <match-media v-slot="{ desktop, desktopMin, tablet, mobile }">
    <aside class="owners__filters-mobile">
      <v-drawer :visible.sync="filtersVisible">
        <filters-mobile
          :default-filters="queryParams"
          :count="params.count"
          @close="filtersVisible = false"
          @apply-filters="applyFilters"
        />
      </v-drawer>
    </aside>

    <aside v-if="!mobile" class="owners__bulk-edit">
      <v-drawer position="right" without-background :visible.sync="bulkEditVisible">
        <owner-bulk-edit-dialog
          :owners="selectedOwnersEdit"
          @close="bulkEditVisible = false"
          @archive="archiveOwners"
          @activate="activateOwners"
          @submitted="editOwnerSubmit"
        ></owner-bulk-edit-dialog>
      </v-drawer>
    </aside>

    <div class="owners">
      <div ref="ownersTopWrapper" class="owners__top-wrapper">
        <div class="owners__header">
          <v-page-title class="owners__title">
            Собственники
            <span class="owners__count">{{ params.count }}</span>
          </v-page-title>
          <v-suggest-search
            v-if="desktop"
            class="owners__searchrow"
            :min-number-length="$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS"
            :min-string-length="$options.MINIMUM_SEARCH_LENGTH_BY_STRING"
            :query-string="queryParams.phone || queryParams.query"
            :get-suggestions-method="getSearchSuggestionsList"
            @reset="resetSuggestions"
          >
            <template #searchResultItem="{item}">
              <owner-suggest-search-item :owner="item" @click="goToOwnerPage(item.id)"></owner-suggest-search-item>
            </template>
          </v-suggest-search>
          <div class="owners__buttons">
            <v-button v-if="!mobile" class="owners__button owners__button--filters" @click="toggleFilters">
              <v-icon-filters class="owners__button-icon owners__button-icon--filters" />
              {{ tablet || desktopMin ? '' : 'Фильтры' }}
            </v-button>
            <v-button primary class="owners__button owners__button--add" @click="addOwner">
              <v-icon-plus class="owners__icon-plus" />
              Добавить собственника
            </v-button>
          </div>
        </div>

        <div class="owners__tabs-wrapper">
          <v-tabs :value="queryParams.status" :primary="mobile" class="owners__tabs" @input="changeStatus">
            <v-tab v-for="(ownerStatus, index) of $options.STATUSES_MAP" :key="index" :name="ownerStatus.value">
              {{ ownerStatus.label }}
            </v-tab>
          </v-tabs>
        </div>

        <v-button v-if="mobile" class="owners__button owners__button--filters" @click="toggleFilters">
          <v-icon-filters class="owners__button-icon owners__button-icon--filters" />
          Фильтры
        </v-button>

        <v-suggest-search
          v-if="!desktop"
          class="owners__search"
          :min-number-length="$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS"
          :min-string-length="$options.MINIMUM_SEARCH_LENGTH_BY_STRING"
          :query-string="queryParams.phone || queryParams.query"
          :get-suggestions-method="getSearchSuggestionsList"
          @reset="resetSuggestions"
        >
          <template #searchResultItem="{item}">
            <owner-suggest-search-item :owner="item" @click="goToOwnerPage(item.id)"></owner-suggest-search-item>
          </template>
        </v-suggest-search>
      </div>
      <div class="owners__content">
        <template v-if="!mobile">
          <owners-table
            :table-height="tableHeight"
            :infinite-loading="infiniteLoading"
            :list="list"
            :infinite-id="infiniteId"
            @edit="editOwner"
            @next-page-request="nextPageRequest"
            @change-sort="applyFilters"
            @bulk-edit="bulkEdit"
          ></owners-table>
        </template>
        <template v-else>
          <owner-list-item
            v-for="owner in list"
            :key="owner.id"
            v-bind="owner"
            @archive="archiveOwners([owner.id])"
            @activate="activateOwners([owner.id])"
            @pending-edit="editOwner(owner)"
            @open="goToOwnerPage(owner.id)"
            @edit="editOwnerMobile(owner)"
          />
          <infinite-loading force-use-infinite-wrapper :identifier="infiniteId" @infinite="infiniteHandler">
            <template #no-more><span></span></template>
            <template #no-results><span></span></template>
          </infinite-loading>
        </template>
      </div>
      <owner-edit-dialog :visible.sync="editDialogVisible" :owner-data="ownerData" @submitted="editOwnerSubmit" />
      <owner-edit-pending-dialog
        :visible.sync="editPendingDialogVisible"
        :draft-data="ownerDraftData"
        :owner-data="ownerData"
        @submitted="editOwnerSubmit"
      ></owner-edit-pending-dialog>
    </div>
  </match-media>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import VIconFilters from '@/components/icons/VFilters.vue'
import VIconPlus from '@/components/icons/VPlus.vue'
import FiltersMobile from '@/components/Owner/Filters/Mobile.vue'
import OwnerListItem from '@/components/Owner/ListItem.vue'
import OwnerEditDialog from '@/components/Owner/EditDialog.vue'
import OwnerEditPendingDialog from '@/components/Owner/EditPendingDialog.vue'
import OwnersTable from '@/components/Owner/Table.vue'
import OwnerSuggestSearchItem from '@/components/Owner/SuggestSearchItem.vue'
import VPageTitle from '@/components/common/VPageTitle.vue'
import VButton from '@/components/common/VButton.vue'
import VDrawer from '@/components/common/VDrawer.vue'
import VSuggestSearch from '@/components/common/VSuggestSearch.vue'
import VTabs from '@/components/common/VTabs.vue'
import VTab from '@/components/common/VTab.vue'
import noticeService from '@/services/notification'
import confirmService from '@/services/confirmation'
import { ownersService } from '@/services/http'
import CancelSourceManager from '@/services/http/CancelSourceManager'
import { loadingService } from '@/services/loading'
// eslint-disable-next-line import/no-cycle
import redirectIfNetworkIssue from '@/router/utils'
import { OWNER_STATUS_ACTIVE, OWNER_STATUS_ARCHIVE } from '@/constants/statuses/owners'
import { MatchMedia } from 'vue-component-media-queries'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import OwnerBulkEditDialog from '@/components/Owner/BulkEditDialog.vue'
import { pluralizeNumeral } from '@/utils/pluralization'
import { getCalculatingHeightTable } from '@/utils/calculatingHeightTable'

const MINIMUM_SEARCH_LENGTH_BY_NUMBERS = 7
const MINIMUM_SEARCH_LENGTH_BY_STRING = 3

const STATUSES_MAP = [
  { label: 'Актуальные', value: OWNER_STATUS_ACTIVE },
  { label: 'Архив', value: OWNER_STATUS_ARCHIVE }
]

export default {
  MINIMUM_SEARCH_LENGTH_BY_NUMBERS,
  MINIMUM_SEARCH_LENGTH_BY_STRING,
  STATUSES_MAP,
  name: 'Owners',
  components: {
    OwnerBulkEditDialog,
    OwnerEditPendingDialog,
    OwnerSuggestSearchItem,
    InfiniteLoading,
    OwnersTable,
    VSuggestSearch,
    VDrawer,
    VIconFilters,
    FiltersMobile,
    MatchMedia,
    VPageTitle,
    VIconPlus,
    VButton,
    OwnerListItem,
    OwnerEditDialog,
    VTabs,
    VTab
  },
  data() {
    return {
      // eslint-disable-next-line vue/max-len
      infiniteId: +new Date(), // Рекомендованное значение по умолчанию +new Date() https://peachscript.github.io/vue-infinite-loading/api/#identifier
      infiniteLoading: false,
      tableHeight: 0,
      filtersVisible: false,
      loading: false,
      list: [],
      ownerData: {},
      ownerDraftData: {},
      ownerStatuses: [],
      ownerCallSources: [],
      params: {
        count: 0,
        offset: 0,
        limit: 10
      },
      editDialogVisible: false,
      editPendingDialogVisible: false,
      bulkEditVisible: false,
      selectedOwnersEdit: []
    }
  },
  computed: {
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    queryParams() {
      return {
        phone: this.$route.query.phone || undefined,
        query: this.$route.query.query || undefined,
        status: this.$route.query.status || OWNER_STATUS_ACTIVE,
        categoryId: Number(this.$route.query.categoryId) || undefined,
        agentId: Number(this.$route.query.agentId) || undefined,
        priceLte: Number(this.$route.query.priceLte) || undefined,
        priceGte: Number(this.$route.query.priceGte) || undefined,
        dateFrom: this.$route.query.dateFrom || undefined,
        dateTo: this.$route.query.dateTo || undefined,
        lastReminderFrom: this.$route.query.lastReminderFrom || undefined,
        lastReminderTo: this.$route.query.lastReminderTo || undefined,
        isDocumentsUploaded: this.$route.query.isDocumentsUploaded || undefined,
        hasEgrp: this.$route.query.hasEgrp || undefined,
        hasAgreement: this.$route.query.hasAgreement || undefined,
        hasTitleDocument: this.$route.query.hasTitleDocument || undefined,
        orderBy: this.$route.query.orderBy || '-created_at'
      }
    }
  },
  watch: {
    editPendingDialogVisible(val) {
      if (!val) {
        this.ownerData = {}
        this.ownerDraftData = {}
      }
    },
    editDialogVisible(val) {
      if (!val) this.ownerData = {}
    },
    loading(val) {
      loadingService.setViewLoading(val)
    }
  },
  mounted() {
    const { ownersTopWrapper } = this.$refs
    this.tableHeight = getCalculatingHeightTable(ownersTopWrapper)
  },
  beforeRouteUpdate(to, from, next) {
    this.$nextTick(() => {
      const fetchResolvers = []
      if (!this.ownerCallSources.length) {
        const ownerCallSourcesRequest = this.fetchOwnerCallSources()
        fetchResolvers.push(ownerCallSourcesRequest)
      }
      if (!this.ownerStatuses.length) {
        const ownerStatusesRequest = this.fetchOwnerStatuses()
        fetchResolvers.push(ownerStatusesRequest)
      }
      if (fetchResolvers.length) {
        Promise.all(fetchResolvers).then(() => {
          this.resetOwnersList()
          this.fetchOwners()
        })
      } else {
        this.resetOwnersList()
        this.fetchOwners()
      }
    })
    next()
  },
  methods: {
    bulkEdit(owners) {
      if (owners.length) {
        this.selectedOwnersEdit = owners.map(owner => this.formattingEditOwnerData(owner))
        this.bulkEditVisible = true
      } else {
        this.selectedOwnersEdit = []
        this.bulkEditVisible = false
      }
    },
    infiniteHandler($state) {
      this.nextPageRequest($state)
    },
    resetOwnersList() {
      this.ownerData = {}
      this.ownerDraftData = {}
      this.params.offset = 0
      this.list = []
    },
    nextPageRequest(infinityState) {
      if (this.list.length) this.fetchOwners({ infinityState })
    },
    formatOwnerList({ list, status = null }) {
      return list.map(owner => {
        const formattedOwner = {
          ...owner,
          agent: owner.ownerProfile?.agent,
          adverts: owner.ownerProfile?.adverts,
          callSource: this.getOwnerCallSource(owner)
        }
        return status ? { ...formattedOwner, status } : formattedOwner
      })
    },
    resetSuggestions() {
      this.updateQueryParams({ phone: undefined, query: undefined })
    },
    updateQueryParams(newParams) {
      this.$router.push({ query: { ...this.queryParams, ...newParams } })
    },
    toggleFilters() {
      this.filtersVisible = !this.filtersVisible
    },
    filtersSearch(filters) {
      this.updateQueryParams(filters)
    },
    applyFilters(filters) {
      this.filtersVisible = false
      this.updateQueryParams(filters)
    },
    changeStatus(status) {
      this.updateQueryParams({ status })
    },
    addOwner() {
      this.editDialogVisible = true
    },
    editOwnerMobile(owner) {
      this.editDialogVisible = true
      this.ownerData = this.formattingEditOwnerData(owner)
    },
    editOwner(owner) {
      this.ownerData = this.formattingEditOwnerData(owner)
      this.fetchOwnerDraft(owner.versionId).then(() => {
        this.editPendingDialogVisible = true
      })
    },
    fetchOwnerDraft(versionId) {
      return ownersService.getOwnerDraft(versionId).then(draftData => {
        this.ownerDraftData = this.formattingEditOwnerData(draftData)
      })
    },
    formattingEditOwnerData(data) {
      return {
        ...data,
        callSource: this.getOwnerCallSource(data),
        status: this.getOwnerStatus(data),
        agent: data.ownerProfile?.agent || {},
        adverts: data.ownerProfile?.adverts || []
      }
    },
    getOwnerCallSource({ ownerProfile }) {
      if (this.ownerCallSources.length) {
        if (ownerProfile) {
          const { callSource: currentCallSources } = ownerProfile
          if (currentCallSources) {
            const source = this.ownerCallSources.find(
              callSource => callSource.id === (currentCallSources.id || currentCallSources)
            )
            if (source) return source
          }
        }
        return this.ownerCallSources.find(callSource => callSource.name.toLowerCase() === 'не выбран')
      }
      return 'Не выбран'
    },
    getOwnerStatus({ ownerProfile }) {
      if (this.ownerStatuses.length && ownerProfile) {
        const { status: currentStatus } = ownerProfile
        if (currentStatus) {
          const statusObject = this.ownerStatuses.find(status => status.id === (currentStatus.id || currentStatus))
          if (statusObject) return statusObject
        }
      }
      return ''
    },
    fetchOwnerStatuses() {
      this.loading = true
      return ownersService
        .getOwnerStatusesMap()
        .then(statuses => {
          this.ownerStatuses = statuses
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchOwnerCallSources() {
      this.loading = true
      return ownersService
        .getCallSourcesMap()
        .then(callSources => {
          this.ownerCallSources = callSources
        })
        .finally(() => {
          this.loading = false
        })
    },
    goToOwnerPage(ownerId) {
      this.$router.push({
        name: 'owner-edit',
        params: { ownerId }
      })
    },
    getSearchSuggestionsList({ inputValue, fetchType }) {
      this.updateQueryParams(
        fetchType === 'number' ? { phone: inputValue, query: undefined } : { phone: undefined, query: inputValue }
      )
      const runWithCancel = CancelSourceManager.withCancelSource('fetch-owners-suggest-search', true)
      return runWithCancel(({ cancelSource }) => {
        const searchParam = fetchType === 'number' ? { phone: inputValue } : { query: inputValue }
        return ownersService.getList({ cancelToken: cancelSource.token, ...searchParam })
      }).then(({ results }) => {
        return this.formatOwnerList({ list: results })
      })
    },
    fetchOwners({ infinityState, ...params } = {}) {
      const runWithCancel = CancelSourceManager.withCancelSource('fetch-owners', true)

      const fetchParams = { ...this.queryParams, ...params }
      const { limit, offset } = this.params

      runWithCancel(({ cancelSource }) => {
        if (!infinityState) this.loading = true
        else this.infiniteLoading = true
        return ownersService.getList({ limit, offset, cancelToken: cancelSource.token, ...fetchParams })
      })
        .then(({ count, results }) => {
          this.params = { ...this.params, count }
          if (results.length) {
            this.list.push(...this.formatOwnerList({ list: results, status: this.queryParams.status }))
            this.params.offset += this.params.limit
            if (infinityState) {
              infinityState.loaded()
            } else {
              this.infiniteId += 1 // сброс скролла на текущее значение
            }
            return
          }
          if (infinityState) infinityState.complete()
        })
        .catch(redirectIfNetworkIssue)
        .finally(() => {
          this.loading = false
          this.infiniteLoading = false
        })
    },
    editOwnerSubmit() {
      this.resetOwnersList()
      this.fetchOwners()
    },
    archiveOwners(ids) {
      const ownersGenitiveCase = pluralizeNumeral(ids.length, 'собственника', 'собственников', 'собственников')
      const successText = pluralizeNumeral(
        ids.length,
        'Собственник архивирован',
        'Собственники архивированы',
        'Собственники архивированы'
      )

      confirmService
        .ask({ title: `Вы точно хотите архивировать ${ownersGenitiveCase}?` })
        .then(() => {
          this.loading = true
          ownersService
            .bulkArchiveOwners(ids)
            .then(() => {
              this.resetOwnersList()
              this.fetchOwners()
              noticeService.success(successText)
            })
            .catch(() => noticeService.error(`Не удалось архивировать ${ownersGenitiveCase}`))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    },
    activateOwners(ids) {
      const ownersGenitiveCase = pluralizeNumeral(ids.length, 'собственника', 'собственников', 'собственников')
      const successText = pluralizeNumeral(
        ids.length,
        'Собственник активирован',
        'Собственники активированы',
        'Собственники активированы'
      )
      confirmService
        .ask({ title: `Вы точно хотите активировать ${ownersGenitiveCase}?` })
        .then(() => {
          this.loading = true
          ownersService
            .bulkActivateOwners(ids)
            .then(() => {
              this.resetOwnersList()
              this.fetchOwners()
              noticeService.success(successText)
            })
            .catch(() => noticeService.error(`Не удалось активировать ${ownersGenitiveCase}`))
            .finally(() => {
              this.loading = false
            })
        })
        .catch(() => {})
    }
  }
}
</script>
