<template>
  <v-bulk-edit-dialog
    class="owner-bulk-edit-dialog"
    @close="close"
    @reset="updateOwnerEditModel(isSingleEdit ? owners[0] : {})"
  >
    <template #itemsCount>
      {{ ownersCountText }}
    </template>
    <template #body>
      <v-form class="owner-bulk-edit-dialog__form" @submit.prevent="onSubmit">
        <template #default>
          <v-form-row>
            <v-form-field label="АГЕНТ">
              <v-select v-model="ownerEditModel.agent" label="name" append-to-body :options="agentOptions" />
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="СТАТУС">
              <v-select v-model="ownerEditModel.status" label="name" append-to-body :options="statusOptions" />
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ИСТОЧНИК ЛИДА">
              <v-select v-model="ownerEditModel.callSource" label="name" append-to-body :options="callSourceOptions" />
            </v-form-field>
          </v-form-row>
        </template>
        <template #footer>
          <v-button class="owner-bulk-edit-dialog__form-action" @click="close">Отменить</v-button>
          <v-button class="owner-bulk-edit-dialog__form-action" primary type="submit">Обновить</v-button>
        </template>
      </v-form>
    </template>
    <template #footer>
      <v-button v-if="isOwnersActive" class="owner-bulk-edit-dialog__action" is-text @click="archive">{{
        `Архивировать ${ownersCountActionText}`
      }}</v-button>
      <v-button v-else class="owner-bulk-edit-dialog__action" is-text @click="activate">{{
        `Активировать  ${ownersCountActionText}`
      }}</v-button>
    </template>
  </v-bulk-edit-dialog>
</template>

<script>
import VButton from '@/components/common/VButton.vue'
import { agentsService, ownersService } from '@/services/http'
import { pluralizeNumeral } from '@/utils/pluralization'
import { formatAgentObject } from '@/utils/formatters'
import VForm from '@/components/form/VForm.vue'
import VFormField from '@/components/form/VFormField.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VSelect from '@/components/common/VSelect.vue'
import { cloneDeep } from '@/utils/common'
import { OWNER_STATUS_ACTIVE } from '@/constants/statuses/owners'
import VBulkEditDialog from '@/components/common/VBulkEditDialog.vue'

export default {
  name: 'OwnerBulkEditDialog',
  components: {
    VBulkEditDialog,
    VSelect,
    VFormRow,
    VFormField,
    VForm,
    VButton
  },
  props: {
    owners: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      errors: {},
      ownerEditModel: {},
      agentOptions: [],
      statusOptions: [],
      callSourceOptions: []
    }
  },
  computed: {
    ownersIds() {
      return this.owners.map(owner => owner.id)
    },
    isOwnersActive() {
      return this.$route.query.status === OWNER_STATUS_ACTIVE
    },
    ownersCount() {
      return this.owners.length
    },
    ownersCountActionText() {
      const text = pluralizeNumeral(this.ownersCount, 'собственника', 'собственников', 'собственников')
      return `${this.ownersCount} ${text}`
    },
    ownersCountText() {
      const text = pluralizeNumeral(this.ownersCount, 'собственник', 'собственника', 'собственников')
      return `${this.ownersCount} ${text}`
    },
    isSingleEdit() {
      return this.ownersCount === 1
    }
  },
  watch: {
    owners: {
      immediate: true,
      handler(newOwnersArray) {
        if (this.isSingleEdit) {
          this.updateOwnerEditModel(newOwnersArray[0])
        } else {
          this.updateOwnerEditModel({})
        }
      }
    }
  },
  created() {
    this.selectAgents()
    this.selectStatuses()
    this.selectCallSources()
  },
  methods: {
    archive() {
      this.$emit('archive', this.ownersIds)
    },
    activate() {
      this.$emit('activate', this.ownersIds)
    },
    updateOwnerEditModel(model) {
      this.ownerEditModel = cloneDeep(model)
    },
    close() {
      this.updateOwnerEditModel({})
      this.$emit('close')
    },
    onSubmit() {
      ownersService.bulkUpdate(this.ownersIds, this.ownerEditModel).finally(() => {
        this.$emit('submitted')
        this.close()
      })
    },
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList.map(agent => formatAgentObject(agent))
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectStatuses() {
      return ownersService.getOwnerStatusesMap().then(statusesList => {
        this.statusOptions = statusesList
      })
    },
    selectCallSources() {
      return ownersService.getCallSourcesMap().then(callSourcesList => {
        this.callSourceOptions = callSourcesList
      })
    }
  }
}
</script>
