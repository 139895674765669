var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var desktop = ref.desktop;
var desktopMin = ref.desktopMin;
var tablet = ref.tablet;
var mobile = ref.mobile;
return [_c('aside',{staticClass:"owners__filters-mobile"},[_c('v-drawer',{attrs:{"visible":_vm.filtersVisible},on:{"update:visible":function($event){_vm.filtersVisible=$event}}},[_c('filters-mobile',{attrs:{"default-filters":_vm.queryParams,"count":_vm.params.count},on:{"close":function($event){_vm.filtersVisible = false},"apply-filters":_vm.applyFilters}})],1)],1),(!mobile)?_c('aside',{staticClass:"owners__bulk-edit"},[_c('v-drawer',{attrs:{"position":"right","without-background":"","visible":_vm.bulkEditVisible},on:{"update:visible":function($event){_vm.bulkEditVisible=$event}}},[_c('owner-bulk-edit-dialog',{attrs:{"owners":_vm.selectedOwnersEdit},on:{"close":function($event){_vm.bulkEditVisible = false},"archive":_vm.archiveOwners,"activate":_vm.activateOwners,"submitted":_vm.editOwnerSubmit}})],1)],1):_vm._e(),_c('div',{staticClass:"owners"},[_c('div',{ref:"ownersTopWrapper",staticClass:"owners__top-wrapper"},[_c('div',{staticClass:"owners__header"},[_c('v-page-title',{staticClass:"owners__title"},[_vm._v(" Собственники "),_c('span',{staticClass:"owners__count"},[_vm._v(_vm._s(_vm.params.count))])]),(desktop)?_c('v-suggest-search',{staticClass:"owners__searchrow",attrs:{"min-number-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS,"min-string-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_STRING,"query-string":_vm.queryParams.phone || _vm.queryParams.query,"get-suggestions-method":_vm.getSearchSuggestionsList},on:{"reset":_vm.resetSuggestions},scopedSlots:_vm._u([{key:"searchResultItem",fn:function(ref){
var item = ref.item;
return [_c('owner-suggest-search-item',{attrs:{"owner":item},on:{"click":function($event){return _vm.goToOwnerPage(item.id)}}})]}}],null,true)}):_vm._e(),_c('div',{staticClass:"owners__buttons"},[(!mobile)?_c('v-button',{staticClass:"owners__button owners__button--filters",on:{"click":_vm.toggleFilters}},[_c('v-icon-filters',{staticClass:"owners__button-icon owners__button-icon--filters"}),_vm._v(" "+_vm._s(tablet || desktopMin ? '' : 'Фильтры')+" ")],1):_vm._e(),_c('v-button',{staticClass:"owners__button owners__button--add",attrs:{"primary":""},on:{"click":_vm.addOwner}},[_c('v-icon-plus',{staticClass:"owners__icon-plus"}),_vm._v(" Добавить собственника ")],1)],1)],1),_c('div',{staticClass:"owners__tabs-wrapper"},[_c('v-tabs',{staticClass:"owners__tabs",attrs:{"value":_vm.queryParams.status,"primary":mobile},on:{"input":_vm.changeStatus}},_vm._l((_vm.$options.STATUSES_MAP),function(ownerStatus,index){return _c('v-tab',{key:index,attrs:{"name":ownerStatus.value}},[_vm._v(" "+_vm._s(ownerStatus.label)+" ")])}),1)],1),(mobile)?_c('v-button',{staticClass:"owners__button owners__button--filters",on:{"click":_vm.toggleFilters}},[_c('v-icon-filters',{staticClass:"owners__button-icon owners__button-icon--filters"}),_vm._v(" Фильтры ")],1):_vm._e(),(!desktop)?_c('v-suggest-search',{staticClass:"owners__search",attrs:{"min-number-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_NUMBERS,"min-string-length":_vm.$options.MINIMUM_SEARCH_LENGTH_BY_STRING,"query-string":_vm.queryParams.phone || _vm.queryParams.query,"get-suggestions-method":_vm.getSearchSuggestionsList},on:{"reset":_vm.resetSuggestions},scopedSlots:_vm._u([{key:"searchResultItem",fn:function(ref){
var item = ref.item;
return [_c('owner-suggest-search-item',{attrs:{"owner":item},on:{"click":function($event){return _vm.goToOwnerPage(item.id)}}})]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"owners__content"},[(!mobile)?[_c('owners-table',{attrs:{"table-height":_vm.tableHeight,"infinite-loading":_vm.infiniteLoading,"list":_vm.list,"infinite-id":_vm.infiniteId},on:{"edit":_vm.editOwner,"next-page-request":_vm.nextPageRequest,"change-sort":_vm.applyFilters,"bulk-edit":_vm.bulkEdit}})]:[_vm._l((_vm.list),function(owner){return _c('owner-list-item',_vm._b({key:owner.id,on:{"archive":function($event){return _vm.archiveOwners([owner.id])},"activate":function($event){return _vm.activateOwners([owner.id])},"pending-edit":function($event){return _vm.editOwner(owner)},"open":function($event){return _vm.goToOwnerPage(owner.id)},"edit":function($event){return _vm.editOwnerMobile(owner)}}},'owner-list-item',owner,false))}),_c('infinite-loading',{attrs:{"force-use-infinite-wrapper":"","identifier":_vm.infiniteId},on:{"infinite":_vm.infiniteHandler},scopedSlots:_vm._u([{key:"no-more",fn:function(){return [_c('span')]},proxy:true},{key:"no-results",fn:function(){return [_c('span')]},proxy:true}],null,true)})]],2),_c('owner-edit-dialog',{attrs:{"visible":_vm.editDialogVisible,"owner-data":_vm.ownerData},on:{"update:visible":function($event){_vm.editDialogVisible=$event},"submitted":_vm.editOwnerSubmit}}),_c('owner-edit-pending-dialog',{attrs:{"visible":_vm.editPendingDialogVisible,"draft-data":_vm.ownerDraftData,"owner-data":_vm.ownerData},on:{"update:visible":function($event){_vm.editPendingDialogVisible=$event},"submitted":_vm.editOwnerSubmit}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }