<template>
  <div class="owner-suggest-search-item" v-on="$listeners">
    <div class="owner-suggest-search-item__adverts">
      <span v-for="advert in owner.ownerProfile.adverts" :key="advert.id" class="owner-suggest-search-item__advert"
        >№{{ advert.id }},
      </span>
    </div>
    <span class="owner-suggest-search-item__name">{{ owner.name }}</span>
    <span class="owner-suggest-search-item__phone">{{ formatPhone(owner.phone) }}</span>
  </div>
</template>

<script>
import { formatPhone } from '@/utils/formatters'

export default {
  name: 'OwnerSuggestSearchItem',
  props: {
    owner: { type: Object, required: true }
  },
  methods: {
    formatPhone
  }
}
</script>
