var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('match-media',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var desktop = ref.desktop;
return [_c('v-table-row',{staticClass:"owner-item",on:{"click":function () { return desktop && _vm.edit(); }}},[(_vm.hasPending)?_c('div',{staticClass:"owner-item__overlay-wrapper",on:{"click":_vm.overlayClick}},[_c('p',{staticClass:"owner-item__overlay-text"},[_vm._v("Отправлен запрос на изменения. "),_c('span',[_vm._v("Посмотреть")])])]):_vm._e(),_c('div',{staticClass:"owner-item__content"},[_c('v-tag-group',{staticClass:"owner-item__documents-status",attrs:{"value":!!_vm.agreementDocumentCount,"group":_vm.$options.DOCUMENTS_STATUSES_GROUP},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var color = ref.color;
var text = ref.text;
return [_c('v-tag',{attrs:{"color":color}},[_vm._v(" "+_vm._s(text))])]}}],null,true)}),_c('div',{staticClass:"owner-item__cell owner-item__cell--date"},[_vm._v(_vm._s(_vm.formattedDate))]),_c('v-button',{staticClass:"owner-item__cell owner-item__cell--owner-name",attrs:{"is-text":"","to":{ name: 'owner-edit', params: { ownerId: _vm.id } }}},[_vm._v(_vm._s(_vm.name))]),_c('router-link',{attrs:{"to":_vm.agentProfileLink},on:{"click":function($event){$event.stopPropagation();}}},[_c('v-avatar',{staticClass:"owner-item__cell owner-item__cell--agent-avatar",attrs:{"size":"small","src":_vm.agentObject.photo}})],1),_c('div',{staticClass:"owner-item__cell owner-item__cell--agent-name"},[_c('router-link',{staticClass:"owner-item__agent-link",attrs:{"to":_vm.agentProfileLink},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(_vm.agentObject.name)+" ")])],1),_c('div',{staticClass:"owner-item__adverts",on:{"click":function($event){$event.stopPropagation();}}},[_c('v-adverts-popover',{attrs:{"adverts":_vm.adverts}})],1),_c('div',{staticClass:"owner-item__cell owner-item__cell--phone"},[_vm._v(_vm._s(_vm.phoneMask))]),_c('div',{staticClass:"owner-item__cell owner-item__cell--email"},[_vm._v(_vm._s(_vm.email))])],1),_c('div',{staticClass:"owner-item__actions",on:{"click":function($event){$event.stopPropagation();}}},[(desktop)?[(_vm.isActive)?_c('v-button-icon',{on:{"click":_vm.archive}},[_c('v-icon-archive')],1):_vm._e(),(_vm.isArchive)?_c('v-button-icon',{on:{"click":_vm.activate}},[_c('v-icon-activate')],1):_vm._e()]:_c('v-popover-actions',{staticClass:"owner-item__popover",scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var onClick = ref.onClick;
return [_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.open)}}},[_vm._v("Открыть")]),_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.edit)}}},[_vm._v("Редактировать")]),(_vm.isRoleAdmin && _vm.isActive)?_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.archive)}}},[_vm._v("Архивировать")]):_vm._e(),(_vm.isRoleAdmin && _vm.isArchive)?_c('v-button-wide',{on:{"click":function($event){return onClick(_vm.activate)}}},[_vm._v("Активировать")]):_vm._e(),_c('v-button-wide',{attrs:{"href":("tel:" + _vm.phone)}},[_vm._v("Позвонить")]),_c('v-button-wide',{attrs:{"href":("https://wa.me/" + (_vm.phone.replace(/^8/, '+7')))}},[_vm._v("Написать в WhatsApp")])]}}],null,true)},[_c('v-button-icon',[_c('v-icon-dots')],1)],1)],2)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }