export const SORT_OPTIONS_MAP = [
  { label: 'Сначала новые', value: '-created_at' },
  { label: 'Сначала старые', value: 'created_at' },
  { label: 'от А до Я', value: 'name' },
  { label: 'от Я до А', value: '-name' },
  { label: 'Cначала с документами', value: 'is_documents_uploaded' },
  { label: 'Cначала без документов', value: '-is_documents_uploaded' },
  { label: 'Сначала без агентов', value: '-agent' },
  { label: 'Сначала с агентами', value: 'agent' }
]
